import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FreightCalculatorState, FreightCalculatorStore } from './freight-calculator.store';

@Injectable({ providedIn: 'root' })
export class FreightCalculatorQuery extends Query<FreightCalculatorState> {
  isLoading$ = this.selectLoading();
  error$ = this.selectError();
  result$ = this.select('result');

  constructor(protected store: FreightCalculatorStore) {
    super(store);
  }
}
