import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { QuoteState } from '../../types/quote.type';
import { QuoteStore } from './quote.store';

@Injectable({ providedIn: 'root' })
export class QuoteQuery extends Query<QuoteState> {
  isLoading$ = this.selectLoading();
  quotes$ = this.select('quotes');
  error$ = this.selectError();

  constructor(protected store: QuoteStore) {
    super(store);
  }
}
