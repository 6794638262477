import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ConversationState, ConversationStore } from './conversation.store';

@Injectable({ providedIn: 'root' })
export class ConversationQuery extends Query<ConversationState> {
  conversationGroups$ = this.select('conversationGroups');
  conversations$ = this.select('conversations');
  conversation$ = this.select('conversation');
  isLoading$ = this.selectLoading();
  error$ = this.selectError();

  constructor(protected store: ConversationStore) {
    super(store);
  }
}
