import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { NGXLogger } from 'ngx-logger';
import { AirFreightCalculatorInput, CargoTypeCode, FCLCalculatorInput, LCLCalculatorInput, RollOnOffCalculatorInput } from '../types/global.types';
import { jsonParseSafe } from '../utils/global.utils';

const { Storage } = Plugins;
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private logger: NGXLogger) {}

  async getTempCalculationInput(data: { cargoTypeCode: CargoTypeCode }) {
    const { cargoTypeCode } = data;
    return Storage.get({
      key: `calculation_input_${cargoTypeCode}`,
    }).then((out) => jsonParseSafe<any>(out.value));
  }

  async setTempCalculationInput(data: {
    input: FCLCalculatorInput | LCLCalculatorInput | RollOnOffCalculatorInput | AirFreightCalculatorInput;
    cargoTypeCode: CargoTypeCode;
  }) {
    const { input, cargoTypeCode } = data;
    return Storage.set({
      key: `calculation_input_${cargoTypeCode}`,
      value: JSON.stringify(input),
    });
  }
}
