import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { SupplierStore } from './supplier.store';
import { SupplierState } from './supplier.store';

@Injectable({ providedIn: 'root' })
export class SupplierQuery extends Query<SupplierState> {
  isLoading$ = this.selectLoading();
  suppliers$ = this.select('suppliers');
  currentSupplier$ = this.select((state) =>
    state?.suppliers?.find((s) => s.id === state.currentSupplierId)
  );
  error$ = this.selectError();

  constructor(protected store: SupplierStore) {
    super(store);
  }
}
